import { Center, ParticularAd } from '@homebox/client';
import classNames from 'classnames';
import GoogleMapReact, { ChangeEventValue, ClickEventValue } from 'google-map-react';
import dynamic from 'next/dynamic';
import React, { FC, useEffect, useRef, useState } from 'react';
import useSupercluster from 'use-supercluster';
import Typography from '@/ui/components/typography';
import useAppData from '@/utils/hooks/useAppData';

const MarkerInfoBox = dynamic(() => import('@/ui/components/map/marker'));
const MarkerInfoBoxParticularAd = dynamic(() => import('@/ui/components/map/markerParticularAd'));

interface pointsCluster {
    type: string,
    properties: {
        cluster: boolean,
        centersIndex: number,
        type: string
    },
    geometry: {
        type: string,
        coordinates: [number, number]
    }
}

// eslint-disable-next-line react/no-unused-prop-types
const CustomMarker: FC<{ lat: number, lng: number }> = ({ children }) => (
    <div>
        {children}
    </div>

);

const MapComponent: FC<{
    listPoints: Center[] | ParticularAd[],
    className?: string,
    autoZoom?: boolean,
    defaultLat?: string,
    defaultLng?: string,
    defaultZoom?: number,
}> = ({
    listPoints,
    className = '',
    autoZoom = false,
    defaultLat,
    defaultLng,
    defaultZoom = 5,
}) => {
    const mapRef = useRef<any>(null);
    const mapsRef = useRef<any>(null);
    const [zoom, setZoom] = useState(defaultZoom);
    const [bounds, setBounds] = useState<number[]>([]);

    const defaultLatIfExist = defaultLat ? parseFloat(defaultLat) : 46.227638;
    const defaultLngIfExist = defaultLng ? parseFloat(defaultLng) : 2.213749;

    const points: pointsCluster[] = listPoints.map((point, index: number) => ({
        type: 'Feature',
        properties:
            {
                cluster: false,
                centersIndex: index,
                type: point['@type'],
            },
        geometry: { type: 'Point', coordinates: [point.longitude, point.latitude] },
    }));

    const { clusters, supercluster } = useSupercluster({
        points,
        bounds,
        zoom,
        options: {
            radius: 75, maxZoom: 20,
        },
    });

    const { selectedMarker, setSelectedMarker } = useAppData();

    useEffect(() => {
        if (selectedMarker) {
            if (mapRef && mapRef.current) {
                mapRef.current.setZoom(12);
                mapRef.current.panTo({ lat: selectedMarker.latitude + 0.04, lng: selectedMarker.longitude });
            }
        }
    }, [selectedMarker]);

    const handleClick = (event: ClickEventValue) => {
        if (event.event.target.closest('.infobox') !== null) {
            return;
        }
        setSelectedMarker(null);
    };

    const handleChange = (event: ChangeEventValue) => {
        setZoom(event.zoom);
        setBounds([event.bounds.nw.lng, event.bounds.se.lat, event.bounds.se.lng, event.bounds.nw.lat]);
    };

    const fitToBounds = () => {
        const latLngBounds = new mapsRef.current.LatLngBounds();
        if (mapsRef.current && listPoints.length > 0) {
            points.forEach((point) => {
                latLngBounds.extend(new mapsRef.current.LatLng(point.geometry.coordinates[1], point.geometry.coordinates[0]));
            });
            mapRef.current.fitBounds(latLngBounds, { width: mapRef.current.width, height: mapRef.current.height });
            // Add this option to dezoom if we don't need cluster
            if (listPoints.length === 1) {
                mapRef.current.setZoom(11);
            }
        } else if (defaultLat && defaultLng) {
            latLngBounds.extend(new mapsRef.current.LatLng(parseFloat(defaultLat), parseFloat(defaultLng)));
            mapRef.current.fitBounds(latLngBounds, { width: mapRef.current.width, height: mapRef.current.height });
            mapRef.current.setZoom(11);
        }
    };

    return (
        <div className={classNames(className, 'h-[480px]')}>
            <GoogleMapReact
                defaultCenter={{
                    lat: defaultLatIfExist, lng: defaultLngIfExist,
                }}
                defaultZoom={zoom}
                onChange={handleChange}
                onClick={handleClick}
                onGoogleApiLoaded={({ map, maps }) => {
                    mapRef.current = map;
                    mapsRef.current = maps;
                    if (autoZoom) {
                        fitToBounds();
                    }
                }}
                options={{ clickableIcons: false }}
                yesIWantToUseGoogleMapApiInternals
            >
                {clusters.map((cluster) => {
                    const [longitude, latitude] = cluster.geometry.coordinates;
                    const { cluster: isCluster, point_count: pointCount } = cluster.properties;

                    const onClick = () => {
                        const expansionZoom = Math.min(
                            supercluster.getClusterExpansionZoom(cluster.id),
                            20,
                        );
                        if (mapRef && mapRef.current) {
                            mapRef.current.setZoom(expansionZoom);
                            mapRef.current.panTo({ lat: latitude, lng: longitude });
                        }
                    };

                    let markerType = '/squareMarker.svg';

                    if (cluster.properties.centersIndex !== undefined) {
                        const currentPoint = listPoints[cluster.properties.centersIndex];
                        if (currentPoint) {
                            if (currentPoint['@type'] === 'Center' && currentPoint.type_center === 'Point Stock') {
                                markerType = '/circleMarker.svg';
                            } else if (currentPoint['@type'] === 'ParticularAd') {
                                markerType = '/blueMarker.svg';
                            }
                        }
                    }

                    return (
                        <CustomMarker
                            key={cluster.geometry.coordinates.toString()}
                            lat={latitude}
                            lng={longitude}
                        >
                            {isCluster
                                ? (
                                    <div
                                        className="grid bg-red place-items-center text-white"
                                        onClick={onClick}
                                        onKeyDown={onClick}
                                        role="button"
                                        style={{
                                            width: `${20 + (pointCount / points.length) * 30}px`,
                                            height: `${20 + (pointCount / points.length) * 30}px`,
                                        }}
                                        tabIndex={0}
                                    >
                                        <Typography className="!text-[14px]" variant="normal">{pointCount}</Typography>
                                    </div>
                                )
                                : (
                                    <button
                                        onClick={() => {
                                            if (mapRef && mapRef.current) {
                                                mapRef.current.setZoom(12);
                                                mapRef.current.panTo({ lat: latitude + 0.04, lng: longitude });
                                            }
                                            setSelectedMarker(listPoints[cluster.properties.centersIndex]);
                                        }}
                                        type="button"
                                    >
                                        <img
                                            alt="markerCenter"
                                            height="18"
                                            src={markerType}
                                            width="18"
                                        />
                                    </button>
                                )}

                        </CustomMarker>
                    );
                })}
                {selectedMarker && selectedMarker['@type'] === 'Center' && (
                    <MarkerInfoBox
                        key={selectedMarker.id}
                        center={selectedMarker}
                        // @ts-ignore
                        lat={selectedMarker.latitude}
                        lng={selectedMarker.longitude}
                    />
                )}
                {selectedMarker && selectedMarker['@type'] === 'ParticularAd' && (
                    <MarkerInfoBoxParticularAd
                        key={selectedMarker.id}
                        // @ts-ignore
                        lat={selectedMarker.latitude}
                        lng={selectedMarker.longitude}
                        particularAd={selectedMarker}
                    />
                )}
            </GoogleMapReact>
        </div>
    );
};

export default MapComponent;
