import classNames from 'classnames';
import React, { FC } from 'react';
import AvisVerifiesLogo from '../avisVerifiesLogo';
import QuotesLightbox from '@/ui/components/homepage/lightbox/quotes';
import useQuoteLightBox from '@/ui/components/quote/hook/useLightBox';
import Star from '@/ui/components/star';
import Typography from '@/ui/components/typography';
import useTranslation from '@/utils/hooks/useTranslation';
import useWebsiteConfig from '@/utils/hooks/useWebsiteConfig';
import { CenterQuoteStats } from '@/utils/quoteClient';

const FakeQuoteGreenButton: FC<Omit<CenterQuoteStats, 'quotes'> & { className?: string }> = ({
    average,
    totalValue,
    className,
}) => {
    const t = useTranslation();

    return (
        <div
            className={
                classNames(
                    'bg-eden min-h-[40px] 2xl:h-[60px] text-white flex items-center justify-center flex-wrap gap-2 w-full px-1',
                    className,
                )
            }
        >
            <AvisVerifiesLogo />
            <Star numberOfStar={average} />
            <Typography className="leading-none" variant="tiny">
                {t('rating_average', { AVERAGE: average, TOTAL: totalValue })}
            </Typography>
        </div>
    );
};

const QuoteGreenButtonWithLightbox: FC<CenterQuoteStats & { className?: string }> = ({
    average,
    totalValue,
    quotes,
    className,
}) => {
    const { isQuoteLightBoxOpened, openQuoteLightBox, closeQuoteLightBox } = useQuoteLightBox();
    const t = useTranslation();

    return (
        <>
            <button
                className={
                    classNames(
                        'bg-eden min-h-[40px] 2xl:h-[60px] text-white flex items-center justify-center flex-wrap gap-2 w-full px-1',
                        className,
                    )
                }
                onClick={openQuoteLightBox}
                type="button"
            >
                <AvisVerifiesLogo />
                <Star numberOfStar={average} />
                <Typography className="leading-none" variant="tiny">
                    {t('rating_average', { AVERAGE: average, TOTAL: totalValue })}
                </Typography>
            </button>
            <QuotesLightbox
                averageTotalQuote={Number(average)}
                averageTotalValue={totalValue}
                isOpen={isQuoteLightBoxOpened}
                onRequestClose={closeQuoteLightBox}
                quoteName={undefined}
                quotes={quotes}
            />
        </>
    );
};

const QuoteButton: FC<CenterQuoteStats & { className?: string }> = ({
    average,
    totalValue,
    className,
    quotes,
}) => {
    const { isSpanish, isFrench, isEurope } = useWebsiteConfig();
    if (!isSpanish && !isFrench && !isEurope) { return null; }
    if (quotes.length === 0) {
        return <FakeQuoteGreenButton average={average} className={className} totalValue={totalValue} />;
    }

    return (
        <QuoteGreenButtonWithLightbox
            average={average}
            className={className}
            quotes={quotes}
            totalValue={totalValue}
        />
    );
};

export default QuoteButton;
