import Image from 'next/image';
import React, { FC } from 'react';
import { ButtonSizes } from '@/ui/components/button';
import AutoCompletePlaces from '@/ui/components/form/field/location/AutoCompletePlaces';
import LocationField from '@/ui/components/form/field/location/locationField';
import Section from '@/ui/components/layout/section';
import AroundMe from '@/ui/components/search/aroundMe/aroundMe';
import SearchForm, { SearchFormSubmit } from '@/ui/components/search/form/search';
import Typography from '@/ui/components/typography';
import Wrapper from '@/ui/components/wrapper';
import useTranslation from '@/utils/hooks/useTranslation';

const FindMyBoxWithImageSection: FC = () => {
    const t = useTranslation();

    return (
        <Section className="bg-provincial-pink pb-0 lg:py-0 2xl:!py-0">
            <Wrapper className="w-full md:w-3/4 lg:w-full">
                <div className="grid gap-y-6 items-center lg:grid-cols-2 lg:gap-x-[84px]">
                    <div className="space-y-6 lg:py-[104px]">
                        <Typography
                            as="h2"
                            className="text-center lg:text-left"
                            variant="h2"
                        >{t('end_page_section_title')}
                        </Typography>
                        <SearchForm className="w-full mt-6 mb-4 2xl:my-8">
                            <div className="flex gap-x-3">
                                <AutoCompletePlaces
                                    className="w-full lg:w-[560px]"
                                    input={<LocationField id="find-my-box-section-input" />}
                                />
                                <SearchFormSubmit
                                    className="shrink-0 lg-down:w-[50px] lg-down:!p-0"
                                    sizeVariant={ButtonSizes.BIG}
                                    textClassName="lg-down:sr-only"
                                    type="submit"
                                />
                            </div>
                            <AroundMe className="mt-2 mb-2 pl-4" />
                        </SearchForm>
                    </div>
                    <div className="lg:hidden lg:justify-self-end m-0 leading-[0px]">
                        <Image
                            alt=""
                            height={236}
                            src="/images/hp-box-footer-landscape.png"
                            width={375}
                        />
                    </div>
                    <div className="lg-down:hidden lg:justify-self-end leading-[0px]">
                        <Image
                            alt=""
                            height={385}
                            layout="fixed"
                            src="/images/hp-box-footer-portrait.png"
                            width={367}
                        />
                    </div>
                </div>
            </Wrapper>
        </Section>
    );
};

export default FindMyBoxWithImageSection;
