import { AvisVerifie, AvisVerifieAverage, Center, Homepage, Media } from '@homebox/client';
import classNames from 'classnames';
import { NextPage } from 'next';
import React, { FC, useState } from 'react';
import Slider from 'react-slick';
import AvisVerifiesLogo from '../components/avisVerifiesLogo';
import BasicLayout from '@/ui/components/bodyLayout/basicLayout';
import Button, { ButtonSizes } from '@/ui/components/button';
import FindBoxButton from '@/ui/components/button/find-a-box';
import FaqComponent, { FaqButton } from '@/ui/components/faq';
import AutoCompletePlaces from '@/ui/components/form/field/location/AutoCompletePlaces';
import LocationField from '@/ui/components/form/field/location/locationField';
import GridCard from '@/ui/components/gridCard';
import QuotesLightbox from '@/ui/components/homepage/lightbox/quotes';
import Section3 from '@/ui/components/homepage/section3/section3';
import Section6 from '@/ui/components/homepage/section6/section-6';
import ResponsiveImage from '@/ui/components/image/responsive';
import Section from '@/ui/components/layout/section';
import Separator from '@/ui/components/layout/separator';
import WrappedSection from '@/ui/components/layout/wrappedSection';
import MapComponent from '@/ui/components/map';
import QuoteGreenButton from '@/ui/components/quote/greenButton';
import QuoteReview from '@/ui/components/quote/review';
import AroundMe from '@/ui/components/search/aroundMe/aroundMe';
import SearchForm, { SearchFormSubmit } from '@/ui/components/search/form/search';
import FindMyBoxWithImageSection from '@/ui/components/section/findBoxWithImage';
import SliderArrow from '@/ui/components/slider/arrow';
import Star from '@/ui/components/star';
import Typography from '@/ui/components/typography';
import Wrapper from '@/ui/components/wrapper';
import useAppData from '@/utils/hooks/useAppData';
import useMediaQuery from '@/utils/hooks/useMediaQuery';
import useTranslation from '@/utils/hooks/useTranslation';
import useWebsiteConfig from '@/utils/hooks/useWebsiteConfig';

export type HomePageProps = {
    page: Homepage,
    centers: Center[],
    quotes: AvisVerifie[]
    quoteAverage: AvisVerifieAverage,
    funnelId: number | null
}

const HomepageMap: FC<{ centers: Center[] }> = ({ centers }) => {
    const { map } = useWebsiteConfig();

    return (
        <div className="text-center">
            <MapComponent
                defaultLat={map.latitude.toString(10)}
                defaultLng={map.longitude.toString(10)}
                defaultZoom={map.zoom}
                listPoints={centers}
            />
        </div>
    );
};

const HomepageImage: FC<{ image: Media | null }> = ({ image }) => (image !== null
    ? (
        <ResponsiveImage
            alt="HOMEBOX"
            height={680}
            layout="responsive"
            src={image.url}
            width={516}
            priority
        />
    ) : null);

const Home: NextPage<HomePageProps> = ({ page, quotes, quoteAverage, centers }: HomePageProps) => {
    const t = useTranslation();
    const [openedLightbox, setOpenedLightbox] = useState<'avis' | null>(null);
    const { setOpenedPoppin } = useAppData<Homepage>();
    const { isEurope } = useWebsiteConfig();

    const averageTotalValue = quoteAverage.total_value;
    const averageTotalQuote = quoteAverage.total_quote;
    const averagePercentage = Math.round((averageTotalQuote / 5) * 100);
    let numberOfSliders = 1;
    if (useMediaQuery('(min-width: 640px)')) {
        numberOfSliders = Math.min(quotes.length, 2);
    }
    if (useMediaQuery('(min-width: 960px)')) {
        numberOfSliders = Math.min(quotes.length, 3);
    }

    const EmptyComponent = () => null;

    const settingsSlider = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: numberOfSliders,
        slidesToScroll: numberOfSliders,
        prevArrow: isEurope ? <EmptyComponent />
            : (
                <SliderArrow
                    className="slick-prev"
                    iconClassName="!text-white hover:text-white"
                    iconName="icon-arrow-left"
                />
            ),
        nextArrow: isEurope ? <EmptyComponent />
            : (
                <SliderArrow
                    className="slick-next"
                    iconClassName="!text-white hover:text-white"
                    iconName="icon-arrow-right"
                />
            ),
    };

    return (
        <BasicLayout>
            <section className="px-[24px] pt-[40px] pb-[60px] lg:px-[40px] lg:pt-8 lg:pb-[104px]">
                <Wrapper className="w-full md:w-3/4 lg:w-full">
                    <div className="grid gap-y-6 lg-down:items-center lg:grid-cols-2 lg:gap-x-[84px]">
                        <div className="flex items-start flex-col justify-center">
                            <div>
                                <Typography
                                    as="h1"
                                    className="uppercase text-red !font-bold mb-4"
                                    variant="tiny"
                                >{page.bloc_1_subtitle}
                                </Typography>
                                <Typography
                                    as="h2"
                                    variant="h1"
                                >{page.bloc_1_title}
                                </Typography>
                            </div>
                            <SearchForm
                                autoComplete="off"
                                className="mt-6 mb-4 2xl:my-8 w-full"
                            >
                                <div className="flex gap-x-3">
                                    <AutoCompletePlaces
                                        className="w-full"
                                        input={<LocationField required />}
                                    />
                                    <SearchFormSubmit
                                        className="shrink-0 sm-down:w-[50px] sm-down:!p-0"
                                        sizeVariant={ButtonSizes.BIG}
                                        textClassName="sm-down:sr-only"
                                        type="submit"
                                    />
                                </div>
                                <AroundMe className="mt-2 pl-4" />
                            </SearchForm>
                            <QuoteGreenButton
                                average={averageTotalQuote}
                                quotes={quotes}
                                totalValue={averageTotalValue}
                            />
                        </div>
                        <HomepageImage image={page.bloc_1_image} />
                    </div>
                </Wrapper>
            </section>
            <WrappedSection
                className="bg-mystic"
                header={(
                    <>
                        <Typography as="h2" variant="h2">{page.bloc_2_title}</Typography>
                        <Typography
                            as="p"
                            variant="normal"
                        >
                            {t('discover_centers', { count: centers.length })}
                        </Typography>
                    </>
                )}
            >
                <HomepageMap centers={centers} />
            </WrappedSection>
            <Section3 />
            <Wrapper>
                <Separator />
            </Wrapper>
            <WrappedSection header={(
                <>
                    <Typography as="h2" variant="h2">{page.card_list.title}</Typography>
                    <Typography
                        as="p"
                        className="text-dark-grey"
                        variant="normal"
                    >
                        {page.card_list.subtitle}
                    </Typography>
                </>
            )}
            >
                <div className="text-center">
                    <GridCard cards={page.card_list.card_detail} />
                    <FindBoxButton onClick={() => setOpenedPoppin('search-box')} sizeVariant={ButtonSizes.MEDIUM} />
                </div>
            </WrappedSection>
            {quoteAverage
                && (
                    <Section className="bg-eden text-white">
                        <Wrapper>
                            <div className="2xl:flex 2xl:items-center 2xl:justify-center">
                                <div className="text-center">
                                    <Typography
                                        as="h2"
                                        className="mb-2"
                                        variant="h2"
                                    >{t(
                                            'avis_verifies_title_satisfaction_percent',
                                            {
                                                percentage: `${averagePercentage}`,
                                                name: 'HOMEBOX',
                                            },
                                        )}
                                    </Typography>
                                    <div
                                        className="flex items-center justify-center md-down:flex-col space-y-1 space-x-2"
                                    >
                                        <div className="relative top-[.4em]">
                                            <AvisVerifiesLogo />
                                        </div>
                                        <Star numberOfStar={averageTotalQuote} />
                                        <Typography variant="tiny">
                                            {t('rating_average_2', {
                                                AVERAGE: averageTotalQuote,
                                                TOTAL: averageTotalValue,
                                            })}
                                        </Typography>
                                    </div>
                                    <Button
                                        className="!bg-white/10 mt-6"
                                        onClick={() => setOpenedLightbox('avis')}
                                        sizeVariant={ButtonSizes.MEDIUM}
                                    >
                                        {t('see_all_reviews')}
                                    </Button>
                                    {quotes.length > 0 && (
                                        <Wrapper className="mt-16 px-6">
                                            <Slider {...settingsSlider}>
                                                {quotes.map((quote: any) => (
                                                    <QuoteReview
                                                        key={quote.review_id.toString()}
                                                        className="text-left px-[56px]"
                                                        quote={quote}
                                                        isInSlider
                                                    />
                                                ))}
                                            </Slider>
                                        </Wrapper>
                                    )}
                                </div>
                            </div>
                        </Wrapper>
                    </Section>
                )}
            <Section6 />
            <WrappedSection header={(<Typography as="h2" variant="h2">{page.faq.title}</Typography>)}>
                <div className="text-center">
                    <div
                        className={classNames(
                            'lg:w-[785px] mx-auto',
                            'mb-6 text-left',
                            'lg-down:divide-y-[1px] lg:divide-x-[1px]',
                        )}
                    >
                        <div>
                            <FaqComponent
                                faqDetails={page.faq.content}
                            />
                        </div>
                    </div>
                    <FaqButton />
                </div>
            </WrappedSection>
            <FindMyBoxWithImageSection />
            <QuotesLightbox
                averageTotalQuote={averageTotalQuote}
                averageTotalValue={averageTotalValue}
                isOpen={openedLightbox === 'avis'}
                onRequestClose={() => setOpenedLightbox(null)}
                quotes={quotes}
            />
        </BasicLayout>
    );
};

export default Home;
